import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react'

import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import { useCartPopupContext } from '@concepts/Cart/store/popupContext'
import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import { GACartProducts } from '@concepts/Checkout/types/Checkout'
import { useCartContext } from '@concepts/Cart/store/context'
import { CartItemSource } from 'src/generated/graphql'

type useAddSaleToCartReturnType = {
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  quantityLeft: number
  warrantyId: number | null
  setWarrantyId: Function
  bidPrice: number
  setBidPrice: Function
  increase: Function
  decrease: Function
  onChangeInput: Function
  addToCart: Function
  getQuantityOnCart: Function
  onChangeWarranty: Function
  paypalLoading: boolean
  setPaypalLoading: Function
}

export type useAddSaleToCartType = {
  sale: SaleDetailsType
}

type addToCartParams = {
  warranty: number | null
  bid: number | null
  source: CartItemSource
}

const formatSaleWarranty = (
  { saleWarranties }: SaleDetailsType,
  quantity: number,
  warrantyId: number | null
) => {
  const saleWarranty = saleWarranties?.find(
    (warranty) => warranty.databaseId === warrantyId
  )

  if (saleWarranty) {
    return [
      {
        category: 'Warranty',
        id: String(saleWarranty.databaseId),
        name: saleWarranty.name,
        price: saleWarranty.priceInCents / 100,
        quantity
      }
    ] as Array<GACartProducts>
  }

  return []
}

const useAddSaleToCart = ({
  sale
}: useAddSaleToCartType): useAddSaleToCartReturnType => {
  const [quantity, setQuantity] = useState(1)
  const [paypalLoading, setPaypalLoading] = useState(false)
  const [warrantyId, setWarrantyId] = useState<number | null>(null)
  const [bidPrice, setBidPrice] = useState(sale.priceToBeatAverageCents)
  const { addSaleToCart, cart } = useCartContext()
  const { handleMiniCartPopup } = useCartPopupContext()

  const getQuantityOnCart = (): number => {
    return cart.items?.find((item) => item.sale.id === sale.id)?.quantity || 0
  }

  const getQuantityLeft = () => {
    return sale.maxForCurrentUser - getQuantityOnCart()
  }

  const [quantityLeft, setQuantityLeft] = useState(getQuantityLeft())

  useEffect(() => {
    setQuantityLeft(getQuantityLeft())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const increase = () => {
    if (quantity < quantityLeft) {
      setQuantity(quantity + 1)
    }
  }

  const decrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const onChangeWarranty = (id: number) => {
    if (warrantyId === id) {
      setWarrantyId(null)
    } else {
      setWarrantyId(id)
    }
  }

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/\D/g, ''))

    setQuantity(value || 1)
  }

  const addToCart = ({ warranty, bid, source }: addToCartParams) => {
    const isNYOP =
      sale.type === SaleTypeEnum.NYOPFull || sale.type === SaleTypeEnum.NYOPMini

    const saleWarranty = formatSaleWarranty(sale, quantity, warranty)

    addSaleToCart(
      sale.id,
      source,
      quantity,
      warranty,
      bid,
      isNYOP ? sale.nyopAveragePriceToken : undefined,
      sale.maxPerUser
    )

    ecommerceAnalytics.trackAddToCart([
      {
        category: sale.category.name,
        id: String(sale.id),
        name: sale.name || sale.title,
        price: sale.priceInCents / 100,
        quantity
      },
      ...saleWarranty
    ])

    handleMiniCartPopup({ isAddItem: true })
    setQuantity(1)
  }

  return {
    quantity,
    setQuantity,
    quantityLeft,
    warrantyId,
    setWarrantyId,
    bidPrice,
    setBidPrice,
    increase,
    decrease,
    onChangeInput,
    addToCart,
    getQuantityOnCart,
    onChangeWarranty,
    paypalLoading,
    setPaypalLoading
  }
}

export default useAddSaleToCart
